import React, {lazy, Suspense} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import './index.css'
import 'leaflet/dist/leaflet.css'

import {useDeviceDetect} from './hooks/use-device-detect'

const App = lazy(() => import('./app'))
const MobileApp = lazy(() => import('./mobile-app'))

const DeviceApp: React.FC = () => {
  const {isMobile} = useDeviceDetect()

  if (isMobile) {
    return <MobileApp />
  }

  return <App />
}

ReactDOM.render(
  <Suspense
    fallback={() => {
      return <i>Loading App</i>
    }}
  >
    <BrowserRouter>
      <DeviceApp />
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
)
